<template>
    <div :key="refreshhKey" class="train_model">
        <!-- reserved -->
        <div v-if="type=='reserved'" class="plac_wrapper">
            <div class="header_train">
                <div v-for="i, idx in placSeats.slice(0, 9)" :key="idx" class="mini_box">
                    <div v-for="j in placSeats[idx]" :key="j.number">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <p
                                    @click="onClick(j)"
                                    :class="getClass(j)"
                                    v-bind="attrs" 
                                    v-on="on"
                                >
                                    {{j.number}}
                                </p>
                            </template>
                            <span>{{ j.number % 2 == 0 ? 'Верхний' : 'Нижний' }}</span>
                        </v-tooltip>
                    </div>
                </div>
            </div>
            <div class="footer_train">
                <div v-for="i, idx in placSeats.slice(9)" :key="idx" class="mini_box">
                    <div v-for="j in i" :key="j.number">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <p
                                    @click="onClick(j)"
                                    :class="getClass(j)"
                                    v-bind="attrs" 
                                    v-on="on"
                                >
                                    {{j.number}}
                                </p>
                            </template>
                            <span>{{ j.number % 2 == 0 ? 'Верхний' : 'Нижний' }}</span>
                        </v-tooltip>
                    </div>
                </div>
            </div>
        </div>
        <!-- coupe -->
        <div v-else-if="type == 'coupe'" class="coupe_wrapper">
            <div class="header_train">
                <div v-for="i, idx in coupeSeats" :key="idx" class="mini_box">
                    <div v-for="j in coupeSeats[idx]" :key="j.number">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <p 
                                    @click="onClick(j)"
                                    :class="getClass(j)"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    {{j.number}}
                                </p>
                            </template>
                            <span>{{ j.number % 2 == 0 ? 'Верхний' : 'Нижний' }}</span>
                        </v-tooltip>
                    </div>
                </div>
            </div>
        </div>
        <!-- sedentary -->
        <div  v-else-if="type == 'sedentary'" class="sitting_wrapper">
            <div class="header_train">
                <div v-for="i, idx in sittingSeats" :key="idx" class="mini_column">
                    <p 
                        @click="onClick(j)"
                        :class="getClass(j)"
                        v-for="j in sittingSeats[idx]" 
                        :key="j.number"
                    >
                        {{j.number}}
                    </p>
                </div>
            </div>
        </div>
        <!-- luxury -->
        <div  v-else-if="type == 'luxury'" class="lux_wrapper">
            <div class="lux_header_train">
                <div v-for="i, idx in luxSeats" :key="idx" class="lux_box">
                    <div v-for="j in luxSeats[idx]" :key="j.number">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <p
                                    @click="onClick(j)"
                                    :class="getClass(j)"
                                    v-bind="attrs" 
                                    v-on="on"
                                >
                                    {{j.number}}
                                </p>
                            </template>
                            <span>{{ j.number % 2 == 0 ? 'Верхний' : 'Нижний' }}</span>
                        </v-tooltip>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    props: {
        clickedSeats: Array,
        selectable: Boolean,
        type: String,
        limit: Number
    },
    data: () => ({
        refreshhKey: 0,
    }),
    computed: {
        ...mapGetters({
            placSeats: 'trainModel/getPlacSeats',
            coupeSeats: 'trainModel/getCoupeSeats',
            sittingSeats: 'trainModel/getSittingSeats',
            luxSeats: 'trainModel/getLuxSeats'
        }),
    },
    methods: {
        getClass(seat) {
            let label = ''
            if (seat.item) {
                if (!seat.item.is_free) {
                    return label = 'bookedSeat'
                }
            }
            if (!this.clickedSeats) return
            if (!this.clickedSeats.length) return
            this.clickedSeats.forEach(el => {
                if (el.item.id == seat.item.id) {
                    label = 'selectedSeat'
                }
            })
            return label
        },
        onClick(seat) {
            this.$emit('onClick', seat)
        }
    },
  mounted() {
      console.log(this.placSeats)
      console.log(this.coupeSeats)
  }
}
</script>

<style lang="scss" scoped>
.plac_wrapper, 
.coupe_wrapper,
.sitting_wrapper,
.lux_wrapper {
    width: 540px;
    margin: 0 auto;
    min-width: 911px;
    height: 130px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    padding: 7px 94px 7px 0px;
}
.plac_wrapper {
    background: url('../../assets/images/train/1.svg');
}
.sitting_wrapper {
    background: url('../../assets/images/train/2.svg');
    .header_train {
        height: 116px;
    }
}
.coupe_wrapper {
    background: url('../../assets/images/train/3.svg');
}
.lux_wrapper {
    background: url('../../assets/images/train/4.svg');
    padding: 7px 217px 7px 0px;
}
.header_train {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-column-gap: 8px;
    width: 693px;
    height: 64px;
    .mini_box {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        text-align: center;
        div {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
.lux_header_train {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 8px;
    width: 486px;
    height: 79px;
    .lux_box {
        padding: 14px 12px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }
    p {
        color: #5386C2;
        margin-bottom: 0;
        cursor: pointer;
    }
}
.mini_box {
    p {
        color: #5386C2;
        margin-bottom: 0;
        cursor: pointer;
    }
}
p {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.mini_column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 6px 0px;
    p {
        color: #5386C2;
        margin-bottom: 0;
        cursor: pointer;
        &:nth-of-type(2) {
            margin-top: 6px;
            margin-bottom: 12px;
        }
        &:nth-of-type(3) {
            margin-bottom: 6px;
        }
    }
}
.footer_train {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-column-gap: 8px;
    width: 693px;
    height: 29px;
    .mini_box {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        text-align: center;
        div {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
    }
}
.selectedSeat {
    background: rgba($color: #FF8413, $alpha: .2) !important;
    color: #FF8413 !important;
}
.bookedSeat {
    color: #E9E9E9 !important;
    pointer-events: none;
}
</style>