var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:_vm.refreshhKey,staticClass:"train_model"},[(_vm.type=='reserved')?_c('div',{staticClass:"plac_wrapper"},[_c('div',{staticClass:"header_train"},_vm._l((_vm.placSeats.slice(0, 9)),function(i,idx){return _c('div',{key:idx,staticClass:"mini_box"},_vm._l((_vm.placSeats[idx]),function(j){return _c('div',{key:j.number},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({class:_vm.getClass(j),on:{"click":function($event){return _vm.onClick(j)}}},'p',attrs,false),on),[_vm._v(" "+_vm._s(j.number)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(j.number % 2 == 0 ? 'Верхний' : 'Нижний'))])])],1)}),0)}),0),_c('div',{staticClass:"footer_train"},_vm._l((_vm.placSeats.slice(9)),function(i,idx){return _c('div',{key:idx,staticClass:"mini_box"},_vm._l((i),function(j){return _c('div',{key:j.number},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({class:_vm.getClass(j),on:{"click":function($event){return _vm.onClick(j)}}},'p',attrs,false),on),[_vm._v(" "+_vm._s(j.number)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(j.number % 2 == 0 ? 'Верхний' : 'Нижний'))])])],1)}),0)}),0)]):(_vm.type == 'coupe')?_c('div',{staticClass:"coupe_wrapper"},[_c('div',{staticClass:"header_train"},_vm._l((_vm.coupeSeats),function(i,idx){return _c('div',{key:idx,staticClass:"mini_box"},_vm._l((_vm.coupeSeats[idx]),function(j){return _c('div',{key:j.number},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({class:_vm.getClass(j),on:{"click":function($event){return _vm.onClick(j)}}},'p',attrs,false),on),[_vm._v(" "+_vm._s(j.number)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(j.number % 2 == 0 ? 'Верхний' : 'Нижний'))])])],1)}),0)}),0)]):(_vm.type == 'sedentary')?_c('div',{staticClass:"sitting_wrapper"},[_c('div',{staticClass:"header_train"},_vm._l((_vm.sittingSeats),function(i,idx){return _c('div',{key:idx,staticClass:"mini_column"},_vm._l((_vm.sittingSeats[idx]),function(j){return _c('p',{key:j.number,class:_vm.getClass(j),on:{"click":function($event){return _vm.onClick(j)}}},[_vm._v(" "+_vm._s(j.number)+" ")])}),0)}),0)]):(_vm.type == 'luxury')?_c('div',{staticClass:"lux_wrapper"},[_c('div',{staticClass:"lux_header_train"},_vm._l((_vm.luxSeats),function(i,idx){return _c('div',{key:idx,staticClass:"lux_box"},_vm._l((_vm.luxSeats[idx]),function(j){return _c('div',{key:j.number},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({class:_vm.getClass(j),on:{"click":function($event){return _vm.onClick(j)}}},'p',attrs,false),on),[_vm._v(" "+_vm._s(j.number)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(j.number % 2 == 0 ? 'Верхний' : 'Нижний'))])])],1)}),0)}),0)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }