<template>
    <div class="select_route">
        <div class="select_route__descr">
            <div class="select_route__descr__location">
                <p>{{item.from_location.name ? item.from_location.name : item.from_location}}</p>
                <p>{{$moment(item.from_date*1000).format('DD MMM, ddd')}}</p>
                <p>{{$moment(item.from_date*1000).format('HH:mm')}}</p>
            </div>
            <div class="select_route__descr__path">
                <p>В пути: {{ formatTime(item.to_date - item.from_date)}}</p>
                <div></div>
                <p>{{item.train_number}}</p>
            </div>
            <div class="select_route__descr__location">
                <p>{{item.to_location.name ? item.to_location.name : item.to_location}}</p>
                <p>{{$moment(item.to_date*1000).format('DD MMM, ddd')}}</p>
                <p>{{$moment(item.to_date*1000).format('HH:mm')}}</p>
            </div>
        </div>
        <div v-if="!hideActions" class="select_route__actions">
            <div v-if="item.coupe_count">
                <p>{{item.coupe_price}} KZT</p>
                <p>Купе <span>12</span></p>
                <v-btn
                    width="160"
                    height="48"
                    color="primary"
                    @click="onClick('coupe')"
                >
                    Выбрать
                </v-btn>
            </div>
            <div v-if="item.reserved_count">
                <p>{{item.reserved_price}} KZT</p>
                <p>Плацкарт <span>12</span></p>
                <v-btn
                    width="160"
                    height="48"
                    color="primary"
                    @click="onClick('reserved')"
                >
                    Выбрать
                </v-btn>
            </div>
            <div v-if="item.luxury_count">
                <p>{{item.luxury_price}} KZT</p>
                <p>Люкс <span>12</span></p>
                <v-btn
                    width="160"
                    height="48"
                    color="primary"
                    @click="onClick('luxury')"
                >
                    Выбрать
                </v-btn>
            </div>
            <div v-if="item.grandee_count">
                <p>{{item.grandee_price}} KZT</p>
                <p>Гранд <span>12</span></p>
                <v-btn
                    width="160"
                    height="48"
                    color="primary"
                    @click="onClick('grandee')"
                >
                    Выбрать
                </v-btn>
            </div>
            <div v-if="item.sedentary_count">
                <p>{{item.sedentary_price}} KZT</p>
                <p>Сидячий <span>12</span></p>
                <v-btn
                    width="160"
                    height="48"
                    color="primary"
                    @click="onClick('sedentary')"
                >
                    Выбрать
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import { getTime } from '@/utils/timeMask.js'
export default {
    props: {
        hideActions: Boolean,
        item: Object
    },
    methods: {
        formatTime(seconds) {
            return getTime(seconds)
        },
        onClick(type) {
            this.$emit('onClick',{...this.item, type})
        },
        formatDate(sec) {
            if (Math.floor(sec/86400)) {
                return `${Math.floor(sec/86400)} день ${this.$moment().startOf('day').seconds(sec).format('HH')} часа ${this.$moment().startOf('day').seconds(sec).format('mm')} минут`
            } else {
                return `${this.$moment().startOf('day').seconds(sec).format('HH')} часов ${this.$moment().startOf('day').seconds(sec).format('mm')} минут`
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.select_route {
    background: #FFFFFF;
    box-shadow: 0px 10px 30px rgba(138, 149, 158, 0.1);
    border-radius: 5px;
    padding: 20px 40px;
    width: 100%;
    margin-top: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    &__descr {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-right: 48px;
        text-align: center;
        p {
            margin-bottom: 0;
            color: #021011;
            font-size: 16px;
            line-height: 19px;
            white-space: nowrap;
        }
        &__location {
            p {
                &:nth-of-type(1), &:nth-of-type(3) {
                    font-weight: 500;
                }
                &:nth-of-type(3) {
                    font-size: 20px;
                    line-height: 24px;
                }
                &:nth-of-type(2) {
                    margin: 6px 0px;
                }
            }
        }
        &__path {
            width: 100%;
            padding: 0px 20px;
            p {
                white-space: unset;
            }
            div {
                width: 100%;
                height: 2px;
                background: #52B1BA;
                margin: 6px 0px;
            }
        }
    }
    &__actions {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-start;
        div {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            text-align: center;
            margin-bottom: 20px;
            p {
                margin-bottom: 0;
                white-space: nowrap;
                &:first-of-type {
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 19px;
                    color: #FF8413;
                }
                &:last-of-type {
                    width: 94px;
                    font-size: 16px;
                    line-height: 19px;
                    color: #021011;
                    margin: 0 22px;
                    span {
                        color: #52B1BA;
                    }
                }
            }
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}
</style>